import React, { Dispatch, SetStateAction } from "react";
import Router from "next/router";
import { useTranslation } from "next-i18next";

import {
  APP_BUILD_INFO,
  getServiceIntroductionInquiryUrl,
  IS_UNDER_PRODUCTION,
} from "@sellernote/_shared/src/constants";
import { ShipdaCurrentLanguage } from "@sellernote/_shared/src/i18n/i18nForShipda";
import { formatDate } from "@sellernote/_shared/src/utils/common/date";
import LinkButton from "@sellernote/_sds-v2/src/components/button/Button/LinkButton";
import TextButton from "@sellernote/_sds-v2/src/components/button/TextButton";
import Divide from "@sellernote/_sds-v2/src/components/Divide";
import SubmenuAnnouncementIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuAnnouncementIcon";
import SubmenuBookIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuBookIcon";
import SubmenuCalendarIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuCalendarIcon";
import SubmenuCodeIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuCodeIcon";
import SubmenuFlightIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuFlightIcon";
import SubmenuGiveMoneyIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuGiveMoneyIcon";
import SubmenuInternationalIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuInternationalIcon";
import SubmenuPlayerIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuPlayerIcon";
import SubMenuSourcingIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubMenuSourcingIcon";
import SubmenuSurpriseIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuSurpriseIcon";
import SubmenuTruckIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuTruckIcon";
import SubmenuVesselIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuVesselIcon";

import useAlibabaSourcing from "../../../../hooks/common/useAlibabaSourcing";

import SubmenuItem from "../components/SubmenuItem/index";

import TempLanguageButton from "../../../TempLanguageButton";
import Logo from "../../Logo";
import User from "../../User";
import MenuItem from "./MenuItem";
import Styled from "./index.styles";

export default function DesktopHeader({
  loggedIn,
  setIsVisibleLoginModal,
  isTransparentBackground,
}: {
  loggedIn: boolean;
  setIsVisibleLoginModal: Dispatch<SetStateAction<boolean>>;
  isTransparentBackground?: boolean;
}) {
  const { t } = useTranslation(["containers"]);

  const { alibabaUrl, alibabaGtmEvent } = useAlibabaSourcing();

  return (
    <Styled.desktopHeader>
      {/* 임시 언어 전환 버튼 */}
      {!IS_UNDER_PRODUCTION && <TempLanguageButton />}

      {!IS_UNDER_PRODUCTION && APP_BUILD_INFO && (
        <div className="build-info">
          {formatDate({
            date: APP_BUILD_INFO.builtAt,
            type: "YY_MM_DD_HH_mm_ss",
          })}
        </div>
      )}

      <Styled.leftContainer>
        <Logo type="header" />

        <nav>
          <Styled.menuContainer>
            <MenuItem label={t("containers:Layout.회사소개")} path="/company" />

            <MenuItem label={t("containers:Layout.서비스")}>
              <Styled.submenuPanel
                width={
                  ShipdaCurrentLanguage.currentLanguage === "ko"
                    ? "869px"
                    : // TODO: 영문 버전에서 Ocean Freight 가 두 줄로 나오는 이슈가 있어서 임시 처리
                      "900px"
                }
              >
                <ul>
                  <Styled.submenuTitle>Forwarding</Styled.submenuTitle>

                  <SubmenuItem
                    label={t("containers:Layout.해상운송")}
                    desc={t("containers:Layout.수출입_해상운임_견적의뢰")}
                    path="/forwarding/ocean"
                    badges={["FCL", "LCL"]}
                    Icon={<SubmenuVesselIcon width={28} height={28} />}
                  />

                  <SubmenuItem
                    label={t("containers:Layout.항공운송")}
                    desc={t("containers:Layout.수출입_항공운임_견적의뢰")}
                    path="/forwarding/air"
                    badges={["AIR"]}
                    Icon={<SubmenuFlightIcon width={28} height={28} />}
                  />

                  <SubmenuItem
                    label={t("containers:Layout.스케줄_조회")}
                    desc={t("containers:Layout.해운/항공사별_스케줄_조회")}
                    path="/forwarding/schedule"
                    badges={["ALL"]}
                    Icon={<SubmenuCalendarIcon width={28} height={28} />}
                  />
                </ul>
                <Divide
                  lineStyle="line"
                  thickness={1}
                  type="vertical"
                  height="230px"
                />
                <ul>
                  <Styled.submenuTitle>Fulfillment</Styled.submenuTitle>

                  <SubmenuItem
                    label={t("containers:Layout.국내_출고")}
                    desc={t(
                      "containers:Layout.스마트스토어__쿠팡__카페24_등_국내출고"
                    )}
                    path="/fulfillment/domestic"
                    badges={["B2B", "B2C"]}
                    Icon={<SubmenuTruckIcon width={28} height={28} />}
                  />

                  <SubmenuItem
                    label={t("containers:Layout.해외_출고")}
                    desc={t(
                      "containers:Layout.Shopify__Cafe24_Global_등_해외출고"
                    )}
                    path="/fulfillment/overseas"
                    badges={["B2B", "B2C"]}
                    Icon={<SubmenuInternationalIcon width={28} height={28} />}
                  />

                  <SubmenuItem
                    label={t("containers:Layout.이벤트_출고")}
                    desc={t(
                      "containers:Layout.와디즈__텀블벅__홈쇼핑_등_단발성_대량출고"
                    )}
                    path="/fulfillment/event"
                    badges={["B2B", "B2C"]}
                    Icon={<SubmenuSurpriseIcon width={28} height={28} />}
                  />
                </ul>

                <Divide
                  lineStyle="line"
                  thickness={1}
                  type="vertical"
                  height="230px"
                />

                <ul>
                  <Styled.submenuTitle>Trade Management</Styled.submenuTitle>

                  <SubmenuItem
                    label={t("containers:Layout.대금결제_T/T_")}
                    desc={t(
                      "containers:Layout.은행_방문없이_온라인_무역대금_이체"
                    )}
                    path="/tt"
                    Icon={<SubmenuGiveMoneyIcon width={28} height={28} />}
                  />

                  <SubmenuItem
                    label={t("containers:Layout.상품소싱_ShipDa_x_Alibaba_")}
                    desc={t(
                      "containers:Layout.상품소싱하고_물류비_할인쿠폰_수령"
                    )}
                    path={alibabaUrl}
                    Icon={<SubMenuSourcingIcon width={28} height={28} />}
                    gtmEvent={alibabaGtmEvent}
                  />
                </ul>
              </Styled.submenuPanel>
            </MenuItem>

            <MenuItem label={t("containers:Layout.기술")}>
              <Styled.submenuPanel width="273px">
                <ul>
                  <SubmenuItem
                    label={t("containers:Layout.오픈_API")}
                    desc={t(
                      "containers:Layout.발주__운송_등_Open_API_문서_제공"
                    )}
                    path="https://developers.ship-da.com/"
                    Icon={<SubmenuCodeIcon width={28} height={28} />}
                  />
                </ul>
              </Styled.submenuPanel>
            </MenuItem>

            <MenuItem label={t("containers:Layout.고객지원")}>
              <Styled.submenuPanel width="264px">
                <ul>
                  <SubmenuItem
                    label={t("containers:Layout.가이드")}
                    desc={t(
                      "containers:Layout.이용방법__용어_및_프로세스__FAQ"
                    )}
                    path="/support/guide"
                    Icon={<SubmenuBookIcon width={28} height={28} />}
                  />

                  <SubmenuItem
                    label={t("containers:Layout.공지사항")}
                    desc={t("containers:Layout.서비스/정책_주요사항_안내")}
                    path="/support/notice"
                    Icon={<SubmenuAnnouncementIcon width={28} height={28} />}
                  />

                  <SubmenuItem
                    label={t("containers:Layout.동영상")}
                    desc={t("containers:Layout.서비스_및_상세_프로세스_영상")}
                    path="/support/onlinelecture"
                    Icon={<SubmenuPlayerIcon width={28} height={28} />}
                  />
                </ul>
              </Styled.submenuPanel>
            </MenuItem>

            <MenuItem
              label={t("containers:Layout.블로그")}
              path="https://www.ship-da.com/blog"
            />
          </Styled.menuContainer>
        </nav>
      </Styled.leftContainer>

      <Styled.authContainer>
        {loggedIn ? (
          <User isTransparentBackground={isTransparentBackground} />
        ) : (
          <>
            <TextButton
              label={t("containers:Layout.회원가입")}
              theme="gray"
              fontSize={14}
              handleClick={() => {
                Router.push("/register");
              }}
              className="custom-header-register-text-button"
            />

            <TextButton
              label={t("containers:Layout.로그인")}
              theme="gray"
              fontSize={14}
              handleClick={() => setIsVisibleLoginModal(true)}
              className="custom-header-register-text-button"
            />
          </>
        )}

        <LinkButton
          label={t("containers:Layout.도입문의")}
          theme="primary"
          borderType="filled"
          size="small"
          href={getServiceIntroductionInquiryUrl()}
        />
      </Styled.authContainer>
    </Styled.desktopHeader>
  );
}
