import { useMemo } from "react";
import { useRecoilValue } from "recoil";

import USER_QUERY from "@sellernote/_shared/src/queries/forwarding/USER_QUERY";
import { FORWARDING_AUTH_ATOMS } from "@sellernote/_shared/src/states/forwarding/auth";

const generateUUID = () =>
  "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

/**
 * 참고) https://dev.azure.com/shipda/Shipda/_workitems/edit/15863
 */
export default function useAlibabaSourcing() {
  const loggedIn = useRecoilValue(FORWARDING_AUTH_ATOMS.USER_LOGGED_IN);

  const { data: userInfo } = USER_QUERY.useGetUserInfo({
    enabled: Boolean(loggedIn),
  });

  const uniqueEventId = `${generateUUID()}-${Date.now()}`;

  const alibabaUrl = `https://offer.alibaba.com/cps/1gstbbb3?bm=cps&src=saf&pid=shipda_official&tp1=${uniqueEventId}${
    userInfo?.id ? `&tp2=${userInfo?.id}` : ""
  }`;

  const alibabaGtmEvent = useMemo(
    () => ({
      event: "alibaba_link_clicked",
      unique_event_id: uniqueEventId,
    }),
    [uniqueEventId]
  );

  return { alibabaUrl, alibabaGtmEvent };
}
