import React from "react";
import Router from "next/router";
import { useTranslation } from "next-i18next";

import { ShipdaCurrentLanguage } from "@sellernote/_shared/src/i18n/i18nForShipda";
import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";
import TextButton from "@sellernote/_sds-v2/src/components/button/TextButton";

import Styled from "./index.styles";

export default function CompanyContactInfo() {
  const { isMobile } = useCheckIsMobile();

  const { t } = useTranslation(["common-new"]);

  return (
    <Styled.contactInfo>
      <li>
        <span className="category">{t("common-new:푸터_대표번호")}:</span>

        <TextButton
          label={
            ShipdaCurrentLanguage.currentLanguage === "ko"
              ? "1544-7789"
              : "+82 2 6956 7218"
          }
          fontSize={14}
          theme="white"
          handleClick={() =>
            Router.push(
              ShipdaCurrentLanguage.currentLanguage === "ko"
                ? "tel:1544-7789"
                : "tel:+82269567218"
            )
          }
          btnAreaPadding={
            isMobile
              ? {
                  top: 8,
                  bottom: 8,
                  right: 8,
                }
              : undefined
          }
        />
      </li>

      <li>
        <span className="category">{t("common-new:푸터_이메일")}:</span>

        <TextButton
          label="cs@ship-da.com"
          fontSize={14}
          theme="white"
          handleClick={() => Router.push("mailto:cs@ship-da.com")}
          btnAreaPadding={
            isMobile
              ? {
                  top: 8,
                  bottom: 8,
                  right: 8,
                }
              : undefined
          }
        />
      </li>

      <li>
        <span className="category">
          {t("common-new:푸터_상담시간")}: <strong>09:00~18:00</strong>
          <br />*{t("common-new:푸터_상담시간_휴무")}
        </span>
      </li>
    </Styled.contactInfo>
  );
}
